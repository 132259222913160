@import 'antd/dist/antd.css';


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



/* .site-card-wrapper {
  margin-top: 20px;
  padding: 20;
} */


::-webkit-scrollbar {
  width: 3px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}


::-webkit-scrollbar-thumb {
  background: rgba(225, 222, 222, 0.85);
  ;
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(182, 181, 181);
}

.ant-input-number{
  line-height: 2.8 !important;
}


.mt-20{
  margin-top: 20px !important;
}

.mb-20{
  margin-bottom: 20px !important;
}