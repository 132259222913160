body{
    background-color: #f0f2f5;
}

#components-form-demo-normal-login .login-form {
    max-width: 300px;
}

#components-form-demo-normal-login .login-form-forgot {
    float: right;
}

#components-form-demo-normal-login .ant-col-rtl .login-form-forgot {
    float: left;
}

#components-form-demo-normal-login .login-form-button {
    width: 100%;
}


.space-align-container {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
}

.space-align-block {
    flex: none;
    margin: 8px 4px;
    padding: 4px;
    border: 1px solid #40a9ff;
}

.space-align-block .mock-block {
    display: inline-block;
    padding: 32px 8px 16px;
    background: rgba(150, 150, 150, 0.2);
}

.no-mrg{
    margin: 0px !important;
    padding: 0px !important;
}
